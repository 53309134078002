<!--
 * @Description: 服务协议
 * @Author: 琢磨先生
 * @Date: 2022-06-22 11:42:31
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-14 03:21:29
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" round icon="plus" @click="onEdit()"
      >添加</el-button
    >
  </el-card>

  <el-card class="box data">
    <el-table :data="tableData" border>
      <el-table-column prop="type_text" label="类型" width="160">
      </el-table-column>
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column width="100" label="状态">
        <template #default="scope">
          <span v-if="scope.row.check_status != 0">
            {{ scope.row.status_text }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_at" width="180" label="创建时间">
      </el-table-column>
      <el-table-column width="180" label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="onCheck(scope.row)"
            v-if="scope.row.check_status == 1"
            >审核</el-button
          >
          <el-popconfirm title="您确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button size="small" type="danger" link class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-drawer
    title="编辑服务、协议"
    v-model="dialogVisible"
    size="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      class="agree_form"
    >
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio
            :label="item.value"
            v-for="item in menus.agreement_types"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item label="标题" prop="title">
        <el-col :span="10">
          <el-input v-model="form.title" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="内容" prop="mod_content">
        <el-col :span="22">
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editorRef"
              :defaultConfig="toolbarConfig"
              mode="simple"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="form.mod_content"
              :defaultConfig="editorConfig"
              mode="simple"
              @onCreated="handleCreated"
            />
          </div>
        </el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-drawer>
  <el-dialog title="审核" v-model="checkDialogVisible" width="1000px">
    <div>类型：{{ form.type_text }}</div>
    <div>标题：{{ form.title }}</div>
    <div
      v-html="form.mod_content"
      style="background: #ebeef5; padding: 10px 20px"
    ></div>
    <template #footer>
      <el-button type="success" @click="onSubmitCheck(true)" :loading="saving"
        >通过</el-button
      >
      <el-button type="primary" @click="onSubmitCheck(false)" :loading="saving"
        >拒绝</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { shallowRef } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import common_api from "../../../http/common_api";

export default {
  components: { Editor, Toolbar },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      checkDialogVisible: false,
      tableData: [],
      form: {},
      rules: {
        type: [{ required: true, message: "必选", trigger: "blur" }],
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        mod_content: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      editorRef: null,
      editorConfig: {},
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "fontSize",
          "fontFamily",
          "lineHeight",
          "bold",
          "underline",
          "italic",
          "through",
          "clearStyle",
          "color",
          "bgColor",
          "|",
          "insertLink",
          "editLink",
          "unLink",
          "blockquote",
          "|",
          "indent",
          "delIndent",
          "justifyLeft",
          "justifyRight",
          "justifyCenter",
          "bulletedList",
          "numberedList",
          "|",
          "redo",
          "undo",
        ],
      },
    };
  },

  created() {
    this.editorRef = shallowRef();
    this.loadData();
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.menus = res.data;
      }
    });
  },
  methods: {
    handleCreated(editor) {
      this.editorRef = editor;
    },
    /**
     *
     */
    loadData() {
      this.$http.get("admin/v1/agreement").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 编辑框
     */
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    /**
     *
     */
    onEdit(item) {
      this.form = {};
      this.form = Object.assign({}, item);
      this.dialogVisible = true;
    },
    /**
     * 审核
     */
    onCheck(item) {
      this.checkDialogVisible = true;
      this.form = item;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http.post("admin/v1/agreement/edit", this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.loadData();
              this.dialogVisible = false;
            }
            this.saving = false;
          });
        }
      });
    },
    /**
     * 审核
     */
    onSubmitCheck(b) {
      this.saving = true;
      this.$http
        .post("admin/v1/agreement/check", {
          id: this.form.id,
          result: b,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
            this.checkDialogVisible = false;
          }
          this.saving = false;
        });
    },
  },
};
</script>

<style  >
.agree_form .ck.ck-editor {
  width: 100%;
  line-height: normal;
}
.agree_form .el-form-item__content {
  line-height: normal;
}

/* .agree_form p{
 margin-block-start: auto;
 margin-block-end: auto;
} */

.agree_form .ck-editor__editable_inline {
  min-height: 400px;
}
</style>